import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ModalsDataTransferService {
  onSelectedItemInSelectBoxGender: EventEmitter<any> = new EventEmitter<any>();
  onSelectedItemInSelectBoxPep: EventEmitter<any> = new EventEmitter<any>();
  onSelectedItemInSelectBoxCountry: EventEmitter<any> = new EventEmitter<any>();
  onSelectedItemInSelectBoxState: EventEmitter<any> = new EventEmitter<any>();
  onCancelPayment: EventEmitter<boolean> = new EventEmitter<any>();
  OnSelectCurrencyModal: EventEmitter<boolean> = new EventEmitter<any>();
  onSeclectedDateYear: EventEmitter<any> = new EventEmitter<any>();
  onSeclectedDateMonth: EventEmitter<any> = new EventEmitter<any>();
  onSeclectedDateDay: EventEmitter<any> = new EventEmitter<any>();
  onSelectPrefix: EventEmitter<any> = new EventEmitter<any>();
  isReadyForBankDep: EventEmitter<any> = new EventEmitter<any>();
  onTournament: EventEmitter<any> = new EventEmitter<any>();
  onCancleActiveBonus: EventEmitter<boolean> = new EventEmitter<boolean>();
  onQuestClaim: EventEmitter<any> = new EventEmitter<any>();

  selectBoxItemsGender: any;
  selectBoxItemsPep: any;
  selectBoxItemsCountry: any;
  selectBoxItemsState: any;
  localCurrency: any;
  localPrefix: any;
  placholders: any;
  selectPepItem: any;
  selectGenderItem: any;
  selectCountryItem: any;
  selectStateItem: any;

  setItemsSelectBoxGender(data: any) {
    this.selectBoxItemsGender = data;
  }
  getSelectedBoxItemsGender() {
    return this.selectBoxItemsGender;
  }
  getSelectedItemInSelectBoxGender() {
    return this.selectGenderItem;
  }
  setItemsSelectBoxPep(data: any) {
    this.selectBoxItemsPep = data;
  }
  getSelectedBoxItemsPep() {
    return this.selectBoxItemsPep;
  }
  getSelectedItemInSelectBoxPep() {
    return this.selectPepItem;
  }
  selectItemInSelectBoxGender(value: any) {
    this.onSelectedItemInSelectBoxGender.emit(value);
    this.selectGenderItem = value;
  }
  setOnCancelPayment() {
    this.onCancelPayment.emit(true);
  }
  selectItemInSelectBoxPep(value: any) {
    this.onSelectedItemInSelectBoxPep.emit(value);
    this.selectPepItem = value;
  }
  selectItemInSelectBoxCountry(value: any) {
    this.onSelectedItemInSelectBoxCountry.emit(value);
    this.selectCountryItem = value;
  }
  selectItemInSelectBoxState(value: any) {
    this.onSelectedItemInSelectBoxState.emit(value);
    this.selectStateItem = value;
  }
  getSelectedItemInSelectBoxState() {
    return this.selectStateItem;
  }
  getSelectedBoxItemsState() {
    return this.selectBoxItemsState;
  }
  getSelectedBoxItemsCountry() {
    return this.selectBoxItemsCountry;
  }
  getSelectedItemInSelectBoxCountry() {
    return this.selectCountryItem;
  }
  setOnSelectCurrency(value: any) {
    this.OnSelectCurrencyModal.emit(value);
  }
  setLocalCurrency(value: any) {
    this.localCurrency = value;
  }
  getLocalCurrency() {
    return this.localCurrency;
  }
  selectYear(value: any) {
    this.onSeclectedDateYear.emit(value);
  }
  selectMonth(value: any) {
    this.onSeclectedDateMonth.emit(value);
  }
  selectDay(value: any) {
    this.onSeclectedDateDay.emit(value);
  }
  setPlacholders(placeholders: any) {
    this.placholders = placeholders;
  }
  getPlacholders() {
    return this.placholders;
  }
  getLocalPrefix() {
    return this.localPrefix;
  }
  selectPrefix(item: any) {
    this.onSelectPrefix.emit(item);
  }
  setLocalPrefix(value: any) {
    this.localPrefix = value;
  }
  setBankDepStatus(value: any) {
    this.isReadyForBankDep.emit(value);
  }
  setTournamentParticipation() {
    this.onTournament.emit(true);
  }
  leaveTournament() {
    this.onTournament.emit(false);
  }
  cancleActiveBonus(value: boolean) {
    this.onCancleActiveBonus.emit(value);
  }
  questClaimed() {
    this.onQuestClaim.emit(true);
  }
}
